/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PartnerDiscoverySource = {
    ALL_OF_US_EVENINGS_WITH_GENETICS_RESEARCH_PROGRAM: 'ALL_OF_US_EVENINGS_WITH_GENETICS_RESEARCH_PROGRAM',
    ALL_OF_US_RESEARCH_PROGRAM_STAFF: 'ALL_OF_US_RESEARCH_PROGRAM_STAFF',
    ALL_OF_US_RESEARCHER_ACADEMY_RTI_INTERNATIONAL: 'ALL_OF_US_RESEARCHER_ACADEMY_RTI_INTERNATIONAL',
    AMERICAN_ASSOCIATION_ON_HEALTH_AND_DISABILITY_AAHD: 'AMERICAN_ASSOCIATION_ON_HEALTH_AND_DISABILITY_AAHD',
    ASIAN_HEALTH_COALITION: 'ASIAN_HEALTH_COALITION',
    CTSA_PACER_COMMUNITY_NETWORK_CPCN: 'CTSA_PACER_COMMUNITY_NETWORK_CPCN',
    DATA_AND_RESEARCH_CENTER_DRC: 'DATA_AND_RESEARCH_CENTER_DRC',
    DELTA_RESEARCH_AND_EDUCATIONAL_FOUNDATION_DREF: 'DELTA_RESEARCH_AND_EDUCATIONAL_FOUNDATION_DREF',
    FIFTYFORWARD_SENIOR_CITIZENS_INC: 'FIFTYFORWARD_SENIOR_CITIZENS_INC',
    IGNITE_NORTHWELL_HEALTH_FEINSTEIN_INSTITUTE_FOR_MEDICAL_RESEARCH: 'IGNITE_NORTHWELL_HEALTH_FEINSTEIN_INSTITUTE_FOR_MEDICAL_RESEARCH',
    NATIONAL_ALLIANCE_FOR_HISPANIC_HEALTH_NAHH: 'NATIONAL_ALLIANCE_FOR_HISPANIC_HEALTH_NAHH',
    NATIONAL_BAPTIST_CONVENTION_USA_INC: 'NATIONAL_BAPTIST_CONVENTION_USA_INC',
    NATIONAL_LATINO_NETWORK_FOR_PRECISION_MEDICINE_AND_HEALTH_DISPARITIES: 'NATIONAL_LATINO_NETWORK_FOR_PRECISION_MEDICINE_AND_HEALTH_DISPARITIES',
    NETWORK_OF_THE_NATIONAL_LIBRARY_OF_MEDICINE_NNLM: 'NETWORK_OF_THE_NATIONAL_LIBRARY_OF_MEDICINE_NNLM',
    PRIDENET_STANFORD_UNIVERSITY: 'PRIDENET_STANFORD_UNIVERSITY',
    PYXIS_PARTNERS: 'PYXIS_PARTNERS',
    SCRIPPS_RESEARCH_INSTITUTE: 'SCRIPPS_RESEARCH_INSTITUTE',
    OTHER: 'OTHER',
    NONE_OF_THE_ABOVE: 'NONE_OF_THE_ABOVE'
} as const;
export type PartnerDiscoverySource = typeof PartnerDiscoverySource[keyof typeof PartnerDiscoverySource];


export function PartnerDiscoverySourceFromJSON(json: any): PartnerDiscoverySource {
    return PartnerDiscoverySourceFromJSONTyped(json, false);
}

export function PartnerDiscoverySourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartnerDiscoverySource {
    return json as PartnerDiscoverySource;
}

export function PartnerDiscoverySourceToJSON(value?: PartnerDiscoverySource | null): any {
    return value as any;
}

