/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AnnotationFilter } from './AnnotationFilter';
import {
    AnnotationFilterFromJSON,
    AnnotationFilterFromJSONTyped,
    AnnotationFilterToJSON,
} from './AnnotationFilter';
import type { Filter } from './Filter';
import {
    FilterFromJSON,
    FilterFromJSONTyped,
    FilterToJSON,
} from './Filter';
import type { ReviewQueryOrderBysInner } from './ReviewQueryOrderBysInner';
import {
    ReviewQueryOrderBysInnerFromJSON,
    ReviewQueryOrderBysInnerFromJSONTyped,
    ReviewQueryOrderBysInnerToJSON,
} from './ReviewQueryOrderBysInner';

/**
 * Query for review instances and annotations
 * @export
 * @interface ReviewQuery
 */
export interface ReviewQuery {
    /**
     * Attributes to include in the returned instances
     * @type {Array<string>}
     * @memberof ReviewQuery
     */
    includeAttributes?: Array<string>;
    /**
     * 
     * @type {Filter}
     * @memberof ReviewQuery
     */
    entityFilter?: Filter | null;
    /**
     * 
     * @type {AnnotationFilter}
     * @memberof ReviewQuery
     */
    annotationFilter?: AnnotationFilter;
    /**
     * Attributes or annotations, and direction to order the results by
     * @type {Array<ReviewQueryOrderBysInner>}
     * @memberof ReviewQuery
     */
    orderBys?: Array<ReviewQueryOrderBysInner>;
    /**
     * Maximum number of results to return. Defaults to 50.
     * @type {number}
     * @memberof ReviewQuery
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof ReviewQuery
     */
    pageSize?: number;
    /**
     * 
     * @type {string}
     * @memberof ReviewQuery
     */
    pageMarker?: string;
}

/**
 * Check if a given object implements the ReviewQuery interface.
 */
export function instanceOfReviewQuery(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ReviewQueryFromJSON(json: any): ReviewQuery {
    return ReviewQueryFromJSONTyped(json, false);
}

export function ReviewQueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReviewQuery {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'includeAttributes': !exists(json, 'includeAttributes') ? undefined : json['includeAttributes'],
        'entityFilter': !exists(json, 'entityFilter') ? undefined : FilterFromJSON(json['entityFilter']),
        'annotationFilter': !exists(json, 'annotationFilter') ? undefined : AnnotationFilterFromJSON(json['annotationFilter']),
        'orderBys': !exists(json, 'orderBys') ? undefined : ((json['orderBys'] as Array<any>).map(ReviewQueryOrderBysInnerFromJSON)),
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'pageMarker': !exists(json, 'pageMarker') ? undefined : json['pageMarker'],
    };
}

export function ReviewQueryToJSON(value?: ReviewQuery | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'includeAttributes': value.includeAttributes,
        'entityFilter': FilterToJSON(value.entityFilter),
        'annotationFilter': AnnotationFilterToJSON(value.annotationFilter),
        'orderBys': value.orderBys === undefined ? undefined : ((value.orderBys as Array<any>).map(ReviewQueryOrderBysInnerToJSON)),
        'limit': value.limit,
        'pageSize': value.pageSize,
        'pageMarker': value.pageMarker,
    };
}

