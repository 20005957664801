/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrderByDirection } from './OrderByDirection';
import {
    OrderByDirectionFromJSON,
    OrderByDirectionFromJSONTyped,
    OrderByDirectionToJSON,
} from './OrderByDirection';

/**
 * Attribute or annotation and the direction
 * @export
 * @interface ReviewQueryOrderBysInner
 */
export interface ReviewQueryOrderBysInner {
    /**
     * Name of the attribute
     * @type {string}
     * @memberof ReviewQueryOrderBysInner
     */
    attribute?: string;
    /**
     * ID of the annotation, immutable
     * @type {string}
     * @memberof ReviewQueryOrderBysInner
     */
    annotation?: string;
    /**
     * 
     * @type {OrderByDirection}
     * @memberof ReviewQueryOrderBysInner
     */
    direction?: OrderByDirection;
}

/**
 * Check if a given object implements the ReviewQueryOrderBysInner interface.
 */
export function instanceOfReviewQueryOrderBysInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ReviewQueryOrderBysInnerFromJSON(json: any): ReviewQueryOrderBysInner {
    return ReviewQueryOrderBysInnerFromJSONTyped(json, false);
}

export function ReviewQueryOrderBysInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReviewQueryOrderBysInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attribute': !exists(json, 'attribute') ? undefined : json['attribute'],
        'annotation': !exists(json, 'annotation') ? undefined : json['annotation'],
        'direction': !exists(json, 'direction') ? undefined : OrderByDirectionFromJSON(json['direction']),
    };
}

export function ReviewQueryOrderBysInnerToJSON(value?: ReviewQueryOrderBysInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attribute': value.attribute,
        'annotation': value.annotation,
        'direction': OrderByDirectionToJSON(value.direction),
    };
}

