/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  StatusAlert,
} from '../models';
import {
    StatusAlertFromJSON,
    StatusAlertToJSON,
} from '../models';

export interface StatusAlertApiDeleteStatusAlertRequest {
    id: number;
}

export interface StatusAlertApiPostStatusAlertRequest {
    statusAlert?: StatusAlert;
}

/**
 * 
 */
export class StatusAlertApi extends runtime.BaseAPI {

    /**
     * Deletes the specified status alert
     */
    async deleteStatusAlertRaw(requestParameters: StatusAlertApiDeleteStatusAlertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteStatusAlert.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/status-alert/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes the specified status alert
     */
    async deleteStatusAlert(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteStatusAlertRaw({ id: id }, initOverrides);
    }

    /**
     * DEPRECATED - Returns status alert information if any exists.
     */
    async getStatusAlertRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StatusAlert>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/status-alert`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusAlertFromJSON(jsonValue));
    }

    /**
     * DEPRECATED - Returns status alert information if any exists.
     */
    async getStatusAlert(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StatusAlert> {
        const response = await this.getStatusAlertRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns status alert information if any exists.
     */
    async getStatusAlertsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<StatusAlert>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v2/status-alert`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StatusAlertFromJSON));
    }

    /**
     * Returns status alert information if any exists.
     */
    async getStatusAlerts(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<StatusAlert>> {
        const response = await this.getStatusAlertsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Pushes new status alert information to all users
     */
    async postStatusAlertRaw(requestParameters: StatusAlertApiPostStatusAlertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StatusAlert>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/status-alert`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatusAlertToJSON(requestParameters.statusAlert),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusAlertFromJSON(jsonValue));
    }

    /**
     * Pushes new status alert information to all users
     */
    async postStatusAlert(statusAlert?: StatusAlert, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StatusAlert> {
        const response = await this.postStatusAlertRaw({ statusAlert: statusAlert }, initOverrides);
        return await response.value();
    }

}
